@font-face {
    font-family: Nexa;
    src: url("https://wellicscommons.s3.eu-west-1.amazonaws.com/nexa-regular-webfont.woff2") format("woff2");
}

body {
    font-family: Nexa;
    height: 100%;
}

.button-wellics {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 40px;
    font-size: 0.875rem;
    font-weight: bold;
    text-decoration: none;
    color: #ffffff !important;
    background-color: #16BECF;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 165px;
    box-sizing: content-box;
}

.button-wellics:hover {
    background-color: #129FB5;
}

.button-wellics:active {
    background-color: #129FB5;
}

.button-wellics-light {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 40px;
    font-size: 0.875rem;
    font-weight: bold;
    text-decoration: none;
    color: #16BECF !important;
    background-color: #D0F2F5;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 165px;
    box-sizing: content-box;
}

.button-wellics-light:hover {
    background-color: #B9E8EC;
}

.button-wellics-light:active {
    background-color: #B9E8EC;
}

.button-orange {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 40px;
    font-size: 0.875rem;
    font-weight: bold;
    text-decoration: none;
    color: #ffffff !important;
    background-color: #FAAF1F;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 165px;
    box-sizing: content-box;
}

.button-orange:hover {
    background-color: #d6950f;
}

.button-orange:active {
    background-color: #d6950f;
}

.button-purple {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 40px;
    font-size: 0.875rem;
    font-weight: bold;
    text-decoration: none;
    color: #ffffff !important;
    background-color: #B573E2;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 165px;
    box-sizing: content-box;
}

.button-purple:hover {
    background-color: #9354B6;
}

.button-purple:active {
    background-color: #9354B6;
}

.button:disabled {
    background-color: #999999;
    cursor: not-allowed;
}

.icon-plus {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    background-repeat: no-repeat;
    background-position: center;
}

.icon-wearable {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    background-repeat: no-repeat;
    background-position: center;
}

.icon-more {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    background-repeat: no-repeat;
    background-position: center;
}

.icon-data {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    background-repeat: no-repeat;
    background-position: center;
}


/* -------------------------------------
GAMIFICATION LEADERBOARD SPECIFIC
------------------------------------- */

.leaderboard-container  {
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 12px;
  line-height: 1.4;
  margin: 0;
  padding: 0;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  border-collapse: separate;
  mso-table-lspace: 0pt;
  mso-table-rspace: 0pt;
  background-color: inherit;
  min-width: 100%;
  width: 100%; }
  .leaderboard-container td {
    font-family: sans-serif;
    font-size: 12px;
    vertical-align: middle;
}

.table-body {
  width: 100%;
}

.td-leaderboard-container {
  display: block;
  margin: 0 auto !important;
  max-width: 580px;
  padding: 10px;
  width: 580px;
}
.td-content {
  box-sizing: border-box;
  display: block;
  Margin: 0 auto;
  max-width: 580px;
  padding: 10px;
}

.p-leaderboard {
  font-family: Nexa;
  font-weight: normal;
  margin: 0;
}

.leaderboard-wrapper {
  height: 100%;
  padding-top: 5px;
  border-radius: 30px;
  background: #CEA2EC;
}

.leaderboard-container {
  position: relative;
  height: 100%;
  border-radius: 30px;
  padding-top: 8px;
  padding-bottom: 0px;
  overflow: hidden;
  margin: 0px auto 0px auto;
  background-color: inherit;
  /*! font-size: ; */
}

.leaderboard-header {
  padding-left: 24px;
  padding-right: 24px;
  text-align: center;
}

.leaderboard-label {
  font-size: 20px;
  line-height: 1.5;
  color: #414A4F;
  font-weight: 700;
  margin-bottom: 0;

}

.leaderboard-list {
  min-width: 250px;
  padding-left: 24px;
  padding-right: 24px;
  border-collapse: separate;
  margin: 16px 0px 0px 0px;
  background-color: inherit;
}

.leaderboard-item {
  height: 25px;
  border-radius: 10px;
  margin-bottom: 16px;
}

.leaderboard-item-top {
/*               background: linear-gradient(88.52deg, #E8E8E8 2.71%, #BEBEBE 103.2%); */
}

.leaderboard-place-wrapper {
  vertical-align: middle;
  padding: 0;
}

.leaderboard-place {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  text-align: center;
}

.leaderboard-place-top {
  /*background: linear-gradient(257.35deg, #CFB216 16.7%, #E2D173 78.11%);*/
}

.leaderboard-avatar-wrapper {
  vertical-align: middle;
  padding: 0;
}
.leaderboard-avatar {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  overflow: hidden;
}
.leaderboard-avatar-top {
  border: 1px solid #FAF7E8;
}

.leaderboard-avatar-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.leaderboard-place-label {
  color: #000000;
  font-size: 14px;
  font-weight: 400;
}

.leaderboard-avatar-position {
  text-align: center;
  vertical-align: middle;
}

.leaderboard-name {
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 0;
  color: #414A4F
}
.leaderboard-right-content {
  width:100%;
  padding-right: 24px;
  padding-left: 24px;
}

.text-align-right {
  text-align: right;
}


/* -------------------------------------
        INLINED WITH htmlemail.io/inline
    ------------------------------------- */
/* -------------------------------------
        RESPONSIVE AND MOBILE FRIENDLY STYLES
    ------------------------------------- */
@media (max-width: 403px) {
    .button-wellics, .button-wellics-light, .button-purple, .button-orange {
        width: 110px;
    }
}

@media only screen and (max-width: 620px) {
    table[class=body] h1 {
        font-size: 28px !important;
        margin-bottom: 10px !important;
    }

    table[class=body] p, table[class=body] ul, table[class=body] ol, table[class=body] td,
    table[class=body] span, table[class=body] a {
        font-size: 0.875rem !important;
    }

    table[class=body] .wrapper, table[class=body] .article {
        padding: 10px !important;
    }

    table[class=body] .content {
        padding: 0 !important;
    }

    table[class=body] .container {
        padding: 0 !important;
        width: 100% !important;
    }

    table[class=body] .main {
        border-left-width: 0 !important;
        border-radius: 0 !important;
        border-right-width: 0 !important;
    }

    table[class=body] .btn table {
        width: 100% !important;
    }

    /*table[class=body] .btn a {
        width: auto;
    }*/

    table[class=body] .img-responsive {
        height: auto !important;
        max-width: 100% !important;
        width: auto !important;
  }

    /* -------------------------------------
                  LEADERBOARD STYLES
        ------------------------------------- */

    table[class=table-body] .td-content {
        padding: 0 !important;
      }

      table[class=table-body] .td-leaderboard-container {
        padding: 0 !important;
        width: 100% !important;
      }

    .leaderboard-container {
      position: relative;
      height: 100%;
      border-radius: 30px;
      padding-top: 16px;
      padding-bottom: 8px;
      overflow: hidden;
      margin: 0px auto 0px auto;
    }

  .leaderboard-list {
    width: 250px;
    border-collapse: separate;
    padding-left: 8px;
    padding-right: 8px;
    margin: 16px 0px 0px 0px;
  }

  .button-wellics {
    max-width: 124px;
  }

}

/* -------------------------------------
        PRESERVE THESE STYLES IN THE HEAD
    ------------------------------------- */
@media all {
    .ExternalClass {
        width: 100%;
    }

    .center {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
    }

    .left {
        display: block;
    }

    .p-m04 {
      margin: 4px 0px;
    }

    .p-mt8 {
      margin-top: 8px;
    }

    .table-transparent {
        background-color: transparent;
    }

    .ExternalClass, .ExternalClass p, .ExternalClass span, .ExternalClass font,
    .ExternalClass td, .ExternalClass div {
        line-height: 100%;
    }

    .apple-link a {
        color: inherit !important;
        font-family: inherit !important;
        font-size: inherit !important;
        font-weight: inherit !important;
        line-height: inherit !important;
        text-decoration: none !important;
    }

    #MessageViewBody a {
        color: inherit;
        text-decoration: none;
        font-size: inherit;
        font-family: inherit;
        font-weight: inherit;
        line-height: inherit;
    }
}