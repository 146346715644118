/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/* width */
body *::-webkit-scrollbar {
    width: 8px !important;
    height: 8px !important;
}

/* Track */
body *::-webkit-scrollbar-track {
    background: none;
}

/* Handle */
body *::-webkit-scrollbar-thumb {
    background: #D3D7DA;
    border-radius: 99px !important;

}

/* Handle on hover */
body *::-webkit-scrollbar-track:hover {
    background: #E6D2AA20;
}


@font-face {
    font-family: 'Nexa';
    font-style: normal;
    font-weight: normal;
    src: url('../assets/fonts/nexa-regular-webfont.woff2');
  }

@font-face {
    font-family: 'Nexa';
    font-style: normal;
    font-weight: bold;
    src: url('../assets/fonts/nexa-bold-webfont.woff2');
  }

@font-face {
    font-family: 'Nexa';
    font-style: normal;
    font-weight: 800;
    src: url('../assets/fonts/NexaExtraBold.woff2');
}

@font-face {
    font-family: 'Nexa';
    font-style: normal;
    font-weight: 900;
    src: url('../assets/fonts/Nexa-Heavy.woff2');
}

  @font-face {
    font-family: 'Nexa';
    font-style: normal;
    font-weight: light;
    src: url('../assets/fonts/nexa-light-webfont.woff2');
  }

/** Ionic CSS Variables **/
:root {
    --ion-font-family: 'Nexa';
    --ion-background-color: #F0F2F3;
    --ion-safe-area-top: 16px;
    /*Additional css variables*/
    --shadow-shape: 0px 4px 24px rgba(138, 138, 138, 0.25);
    --shadow-button: 0px 0px 6px rgba(138, 138, 138, 0.24);
    /** primary **/
    --ion-color-primary: #6EE2E4;
    --ion-color-primary-rgb: 110, 226 ,228;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #6EE2E4;
    --ion-color-primary-tint: #6EE2E4;

    /** secondary **/
    --ion-color-secondary: #EDDFC3;
    --ion-color-secondary-rgb: 237, 223, 195;
    --ion-color-secondary-contrast: #000000;
    --ion-color-secondary-contrast-rgb: 0, 0, 0;
    --ion-color-secondary-shade: #E6D2AA;
    --ion-color-secondary-tint: #FAF6EE;

    /** success **/
    --ion-color-success: #28A745;
    --ion-color-success-rgb: 240, 255, 244;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #F0FFF4;
    --ion-color-success-tint: #F0FFF4;

    /** warning **/
    --ion-color-warning: #FAAF1F;
    --ion-color-warning-rgb: 250, 175, 31;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #FAAF1F;
    --ion-color-warning-tint: #FAAF1F;

    /** danger **/
    --ion-color-danger: #DA4E53;
    --ion-color-danger-rgb: 218, 78, 83;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #D2232A;
    --ion-color-danger-tint: #EDA7A9;

    /** dark **/
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    /** medium **/
    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;

    /** light **/
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;
}

/*@media (prefers-color-scheme: dark) {*/
/*    !**/
/*     * Dark Colors*/
/*     * -------------------------------------------*/
/*     *!*/
/*    body {*/
/*        --ion-color-primary: #428cff;*/
/*        --ion-color-primary-rgb: 66, 140, 255;*/
/*        --ion-color-primary-contrast: #ffffff;*/
/*        --ion-color-primary-contrast-rgb: 255, 255, 255;*/
/*        --ion-color-primary-shade: #3a7be0;*/
/*        --ion-color-primary-tint: #5598ff;*/

/*        --ion-color-secondary: #50c8ff;*/
/*        --ion-color-secondary-rgb: 80, 200, 255;*/
/*        --ion-color-secondary-contrast: #ffffff;*/
/*        --ion-color-secondary-contrast-rgb: 255, 255, 255;*/
/*        --ion-color-secondary-shade: #46b0e0;*/
/*        --ion-color-secondary-tint: #62ceff;*/

/*        --ion-color-tertiary: #6a64ff;*/
/*        --ion-color-tertiary-rgb: 106, 100, 255;*/
/*        --ion-color-tertiary-contrast: #ffffff;*/
/*        --ion-color-tertiary-contrast-rgb: 255, 255, 255;*/
/*        --ion-color-tertiary-shade: #5d58e0;*/
/*        --ion-color-tertiary-tint: #7974ff;*/

/*        --ion-color-success: #2fdf75;*/
/*        --ion-color-success-rgb: 47, 223, 117;*/
/*        --ion-color-success-contrast: #000000;*/
/*        --ion-color-success-contrast-rgb: 0, 0, 0;*/
/*        --ion-color-success-shade: #29c467;*/
/*        --ion-color-success-tint: #44e283;*/

/*        --ion-color-warning: #ffd534;*/
/*        --ion-color-warning-rgb: 255, 213, 52;*/
/*        --ion-color-warning-contrast: #000000;*/
/*        --ion-color-warning-contrast-rgb: 0, 0, 0;*/
/*        --ion-color-warning-shade: #e0bb2e;*/
/*        --ion-color-warning-tint: #ffd948;*/

/*        --ion-color-danger: #ff4961;*/
/*        --ion-color-danger-rgb: 255, 73, 97;*/
/*        --ion-color-danger-contrast: #ffffff;*/
/*        --ion-color-danger-contrast-rgb: 255, 255, 255;*/
/*        --ion-color-danger-shade: #e04055;*/
/*        --ion-color-danger-tint: #ff5b71;*/

/*        --ion-color-dark: #f4f5f8;*/
/*        --ion-color-dark-rgb: 244, 245, 248;*/
/*        --ion-color-dark-contrast: #000000;*/
/*        --ion-color-dark-contrast-rgb: 0, 0, 0;*/
/*        --ion-color-dark-shade: #d7d8da;*/
/*        --ion-color-dark-tint: #f5f6f9;*/

/*        --ion-color-medium: #989aa2;*/
/*        --ion-color-medium-rgb: 152, 154, 162;*/
/*        --ion-color-medium-contrast: #000000;*/
/*        --ion-color-medium-contrast-rgb: 0, 0, 0;*/
/*        --ion-color-medium-shade: #86888f;*/
/*        --ion-color-medium-tint: #a2a4ab;*/

/*        --ion-color-light: #222428;*/
/*        --ion-color-light-rgb: 34, 36, 40;*/
/*        --ion-color-light-contrast: #ffffff;*/
/*        --ion-color-light-contrast-rgb: 255, 255, 255;*/
/*        --ion-color-light-shade: #1e2023;*/
/*        --ion-color-light-tint: #383a3e;*/
/*    }*/

/*    !**/
/*     * iOS Dark Theme*/
/*     * -------------------------------------------*/
/*     *!*/
/*    .ios body {*/
/*        --ion-background-color: #000000;*/
/*        --ion-background-color-rgb: 0, 0, 0;*/

/*        --ion-text-color: #ffffff;*/
/*        --ion-text-color-rgb: 255, 255, 255;*/

/*        --ion-color-step-50: #0d0d0d;*/
/*        --ion-color-step-100: #1a1a1a;*/
/*        --ion-color-step-150: #262626;*/
/*        --ion-color-step-200: #333333;*/
/*        --ion-color-step-250: #404040;*/
/*        --ion-color-step-300: #4d4d4d;*/
/*        --ion-color-step-350: #595959;*/
/*        --ion-color-step-400: #666666;*/
/*        --ion-color-step-450: #737373;*/
/*        --ion-color-step-500: #808080;*/
/*        --ion-color-step-550: #8c8c8c;*/
/*        --ion-color-step-600: #999999;*/
/*        --ion-color-step-650: #a6a6a6;*/
/*        --ion-color-step-700: #b3b3b3;*/
/*        --ion-color-step-750: #bfbfbf;*/
/*        --ion-color-step-800: #cccccc;*/
/*        --ion-color-step-850: #d9d9d9;*/
/*        --ion-color-step-900: #e6e6e6;*/
/*        --ion-color-step-950: #f2f2f2;*/

/*        --ion-item-background: #000000;*/

/*        --ion-card-background: #1c1c1d;*/
/*    }*/

/*    .ios ion-modal {*/
/*        --ion-background-color: var(--ion-color-step-100);*/
/*        --ion-toolbar-background: var(--ion-color-step-150);*/
/*        --ion-toolbar-border-color: var(--ion-color-step-250);*/
/*    }*/


/*    !**/
/*     * Material Design Dark Theme*/
/*     * -------------------------------------------*/
/*     *!*/
/*    .md body {*/
/*        --ion-background-color: #121212;*/
/*        --ion-background-color-rgb: 18, 18, 18;*/

/*        --ion-text-color: #ffffff;*/
/*        --ion-text-color-rgb: 255, 255, 255;*/

/*        --ion-border-color: #222222;*/

/*        --ion-color-step-50: #1e1e1e;*/
/*        --ion-color-step-100: #2a2a2a;*/
/*        --ion-color-step-150: #363636;*/
/*        --ion-color-step-200: #414141;*/
/*        --ion-color-step-250: #4d4d4d;*/
/*        --ion-color-step-300: #595959;*/
/*        --ion-color-step-350: #656565;*/
/*        --ion-color-step-400: #717171;*/
/*        --ion-color-step-450: #7d7d7d;*/
/*        --ion-color-step-500: #898989;*/
/*        --ion-color-step-550: #949494;*/
/*        --ion-color-step-600: #a0a0a0;*/
/*        --ion-color-step-650: #acacac;*/
/*        --ion-color-step-700: #b8b8b8;*/
/*        --ion-color-step-750: #c4c4c4;*/
/*        --ion-color-step-800: #d0d0d0;*/
/*        --ion-color-step-850: #dbdbdb;*/
/*        --ion-color-step-900: #e7e7e7;*/
/*        --ion-color-step-950: #f3f3f3;*/

/*        --ion-item-background: #1e1e1e;*/

/*        --ion-toolbar-background: #1f1f1f;*/

/*        --ion-tab-bar-background: #1f1f1f;*/

/*        --ion-card-background: #1e1e1e;*/
/*    }*/
/*}*/
