@keyframes appear {
    from {
     opacity: 0;
    }
    to {
       opacity: 1;
    }
}


.videoPlayer iframe{
    position:absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width:100%;
    height: 100%;
    border-radius: 8px;
    max-height: 60vh;
    overflow: hidden;
    background: black;
}

ion-slides {
    height: 320px;
}

.svgWhite * {
    stroke: white;
}

@media (max-width:780px) {
/*//change min-width to your selected value*/
.MuiGrid-grid-xs-12.blogGridItem {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
}
}

.customSnackbar .MuiPaper-root {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}


.whiteIcon * {
         stroke: white;
         color: white;
     }

.whiteFillIcon * {
    fill: white;
}
.swiper {
    width: 100%
}

#main-graph .apexcharts-toolbar{
    z-index:7;
}

input[type='number'] {
    -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}