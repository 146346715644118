.small-slide ion-range {
  padding: 0 11px;
  --knob-size: 28px;
  --height: 32px;
  --bar-height: 32px;
}
ion-range {
  padding: 0 18px;
  --knob-size: 38px;
  --height: 44px;
  --bar-height: 44px;
}
ion-range::part(bar) {
  opacity: 0;
}
ion-range::part(tick) {
  opacity: 0;
}
ion-range::part(tick-active) {
  opacity: 0;
}
ion-range::part(bar-active) {
  opacity: 0;
}
ion-range::part(pin) {
  background: #C4CACE;
  border-radius: 5px;
  color: #FFFFFF;
}
ion-range::part(knob) {
  border: 2px solid #FFFFFF;
  background: linear-gradient(100deg, #FFFFFF 0%, #F0F2F3 100%);
}