.booleanLogData {
  width: 382px;
  margin-inline: 40px;
  border: 1px solid #E2E5E6;
  border-radius: 10px;
  margin-bottom: 50px;
}

.booleanLogData .rdrCalendarWrapper {
  border: 1px solid #E2E5E6;
  border-radius: 10px;
  margin-inline: 40px;
  margin-bottom: 24px;
}
.booleanLogData.rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after {
  content: none
}
.booleanLogData .rdrDayStartPreview {
  display: none;
}

.booleanLogData .rdrMonthPicker {
  background: #E2E5E6;
  border-radius: 5px;
  height: 24px;
}
.booleanLogData .rdrDays {
  height: fit-content;
  font-size: 12px;
  /* justify-content: center; */
}
.booleanLogData .rdrMonthAndYearPickers select {
  padding-top: 5px;
  padding-bottom: 5px;
}
.booleanLogData .rdrDayPassive {
  height: 50px;
  width: 50px;
  pointer-events: unset;
}
.booleanLogData .rdrDay {
  height: 50px;
  width: 50px;
}
.booleanLogData .rdrMonth {
  padding-bottom: 16px;
  width: 370px;
}
.booleanLogData .rdrMonthAndYearWrapper {
  height: 50px;
}
.booleanLogData .dayWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  font-size: 12px;
  font-weight: 100;
}
.booleanLogData .dayLabel {
  color: #414A4F;
  font-size: 12px,
}
.booleanLogData .rdrDayPassive .dayLabel {
  color: #C4CACE
}
.booleanLogData .rdrDayToday .rdrDayNumber span:after {
  background-color: #B573E2;
  bottom: 0;
  height: 3px;
  width: 29px;
  border-radius: 5px 5px 0px 0px;
}
.booleanLogData .rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after {
  background: #B573E2;
}
.booleanLogData .dayWrapper.selectedDayWrapper {
  background-color: #FEF7E9;
}
.booleanLogData .dayWrapper.defaultDayWrapper {
  background-color: #FEF7E9;
}
.booleanLogData .rdrNextPrevButton {
  background-color: #E2E5E6;
  border-radius: 5px;
}
@media (max-width: 1115px) {
  .booleanLogData {
    width: 328px;
    padding: 16px 10px;
    margin-left: auto;
    margin-right: auto;
  }
  .booleanLogData .rdrMonth {
    width: 308px;
    padding: 0;
  }
  .booleanLogData .rdrDayPassive {
    height: 44px;
    width: 44px;
  }
  .booleanLogData .rdrDay {
    height: 44px;
    width: 44px;
  }
}