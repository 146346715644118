.logModal {
  width: 382px;
  margin-inline: 40px;
  border: 1px solid #E2E5E6;
  border-radius: 10px;
  margin-bottom: 24px;
}
.logModal.logData {
  margin-inline: unset;
}

.logModal .rdrCalendarWrapper {
  border: 1px solid #E2E5E6;
  border-radius: 10px;
  margin-inline: 40px;
  margin-bottom: 24px;
}

.logModal .rdrSelected {
  position: 'ab';
}
.logModal.rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after {
  content: none
}
.logModal .rdrDayStartPreview {
  display: none;
}

.logModal .rdrMonthPicker {
  background: #E2E5E6;
  border-radius: 5px;
  height: 24px;
}
.logModal .rdrDays {
  height: fit-content;
  font-size: 12px;
  /* justify-content: center; */
}
.logModal .rdrMonthAndYearPickers select {
  padding-top: 5px;
  padding-bottom: 5px;
}
.logModal .rdrDayPassive {
  height: 50px;
  width: 50px;
  pointer-events: unset;
}
.logModal .rdrDay {
  height: 50px;
  width: 50px;
}
.logModal .rdrMonth {
  padding-bottom: 16px;
  width: 370px;
}
.logModal .rdrMonthAndYearWrapper {
  height: 50px;
}
.logModal .dayWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  font-size: 12px;
  font-weight: 100;
}
.logModal .dayLabel {
  color: #414A4F;
  font-size: 12px,
}
.logModal .rdrDayPassive .emptyDayWrapper .dayLabel {
  color: #C4CACE
}
.logModal .rdrDayToday .rdrDayNumber span:after {
  background-color: #B573E2;
  bottom: 0;
  height: 3px;
  width: 29px;
  border-radius: 5px 5px 0px 0px;
}
.logModal .rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after {
  background: #B573E2;
}
.logModal .dayWrapper.selectedDayWrapper {
  background-color: #16BECF;
}
.logModal .rdrPassive .dayWrapper.selectedDayWrapper {
  background-color: #16BECF;
}
.logModal.logData .rdrDayPassive .dayWrapper.defaultDayWrapper {
  background-color: unset;
}
.logModal.logData .rdrDayPassive .dayWrapper.selectedDayWrapper {
  background-color: unset;
}
.logModal.logData .rdrDayPassive .dayLabel {
  color: #C4CACE;
}
.logModal .dayWrapper.defaultDayWrapper {
  background-color: #E8F8FA;
}
.logModal .dayWrapper.selectedDayWrapper.booleanDay {
  background-color: #FEF7E9;
}
.logModal .dayWrapper.defaultDayWrapper.booleanDay {
  background-color: #FEF7E9;
}
.logModal .rdrNextPrevButton {
  background-color: #E2E5E6;
  border-radius: 5px;
}
@media (max-width: 1115px) {
  .logModal {
    width: 328px;
    padding: 16px 10px;
    margin-left: auto;
    margin-right: auto;
  }
  .logModal.logData {
    width: 378px;
  }
  .logModal.logData .rdrMonth {
    width: 350px;
  }
  .logModal .rdrMonth {
    width: 308px;
    padding: 0;
  }
  .logModal .rdrDayPassive {
    height: 44px;
    width: 44px;
  }
  .logModal.logData .rdrDayPassive {
    height: 50px;
    width: 50px;
  }
  .logModal .rdrDay {
    height: 44px;
    width: 44px;
  }
  .logModal.logData .rdrDay {
    height: 50px;
    width: 50px;
  }
}